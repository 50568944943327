import("../../../../modules/system/assets/js/framework-bundle")
import { CookieManager } from "../../../../plugins/seba/ockekse/assets/modules/cookieManager"

//Import Jquery
import "../../../../node_modules/jquery/dist/jquery.min.js";

// Swiper Slider Module
import * as Swiper from "./modules/swiper"
import "./modules/navigation"

document.addEventListener("DOMContentLoaded", function () {
    const cookieManager = new CookieManager()
    cookieManager.init()
    Swiper.init();
})

$(document).on('click', '.faq-question', function () {

    var answerElement = $(this).next('.faq-answer');

    answerElement.slideToggle();

    var arrowIcons = $(this).find('.arrow-faq');
    arrowIcons.toggleClass('hidden');
});





