class CookieManager
{

    init()
    {
        const cookieBanner               = document.querySelector("div[data-cookie-banner]")
        const cookieManager              = document.querySelector("div[data-cookie-manager]")
        const body                       = document.querySelector("body")
        const cookieBannerManagerElement = document.querySelectorAll("ul[data-cb-manager-element]")

        cookieBannerManagerElement.forEach((element) => {
            element.querySelectorAll("button").forEach((buttonElement) => {
                buttonElement.addEventListener("click", function (e, ele) {
                    e.preventDefault()
                    e.stopImmediatePropagation()

                    this.closest("ul").classList.toggle("bg-gray-50")
                    this.closest("ul").querySelectorAll("li")[1].classList.toggle("hidden")
                    this.querySelector("svg").classList.toggle("-rotate-180")
                })
            })
        })

        /**cookie manager auf der Seite zeigen */
        const cookieManagerShowButton = document.querySelectorAll("[data-show-cm]")
        if (cookieManagerShowButton) {
            cookieManagerShowButton.forEach(button => button.addEventListener("click", function (e) {
                e.preventDefault()
                body.classList.add("overflow-hidden")
                if (cookieBanner) {
                    cookieBanner.classList.add("hidden")
                }

                cookieManager.classList.remove("hidden")
            }))
        }

        // cookie banner
        if (cookieBanner) {
            const cookieBannerSetupButton = document.querySelector("button[data-cookie-setup-button]")
            const cookieBannerButtons     = document.querySelectorAll("button[data-cookie-button]")

            if (parseInt(cookieBanner.dataset.cbFull)) {
                body.classList.add("overflow-hidden")
            }

            cookieBannerSetupButton.addEventListener("click", function (e) {
                e.preventDefault()
                cookieBanner.classList.add("hidden")
                cookieManager.classList.remove("hidden")

            })
            cookieBannerButtons.forEach(button => button.addEventListener("click", function () {
                body.classList.remove("overflow-hidden")
            }))
        }
    }
}

export {CookieManager}
