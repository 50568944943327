  // import Swiper bundle with all modules installed
import Swiper from '../../../../../node_modules/swiper/swiper-bundle.esm.browser.min.js';

// import styles bundle
import '../../../../../node_modules/swiper/swiper-bundle.min.css';

// sm: '640px',   
// md: '768px',   
// lg: '1024px',  
// xl: '1280px',  
// '2xl': '1536px'

export function init(){
    
    if($('.swiper-center-mobile').length > 0){
        var swiperMobile = new Swiper('.swiper-center-mobile', {
            centeredSlides:true,
            loop:true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {            
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 2.8,
                    spaceBetween: 0,
                },
                900: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1536: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                },
                1600: {
                    slidesPerView: 6,
                    spaceBetween: 0,
                },
                1900: {
                    slidesPerView: 7,
                    spaceBetween: 0,
                },
                2200: {
                    slidesPerView: 8,
                    spaceBetween: 0,
                },
            },
            on: {
                slideChangeTransitionStart: function (swiper) {
                    let el = $(swiper.wrapperEl);
                    el.find('.swiper-slide-prev-2').removeClass('swiper-slide-prev-2');
                    console.log(el.find('.swiper-slide-prev').prev().addClass('swiper-slide-prev-2').prev().addClass('swiper-slide-prev-2'));
                },
                resize: function (swiper) {
                    let el = $(swiper.wrapperEl);
                    el.find('.swiper-slide-prev-2').removeClass('swiper-slide-prev-2');
                    console.log(el.find('.swiper-slide-prev').prev().addClass('swiper-slide-prev-2').prev().addClass('swiper-slide-prev-2'));
                },
            }
        });
    }
    
    if($('.swiper-3-mobile-slider').length > 0){
        var swiperSimple = new Swiper('.swiper-3-mobile-slider', {
            loop: true,
            lazy: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {            
                350: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1300: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            }
        });
    }

    if($('.swiper-4-half-slides').length > 0){
        var swiperSimple2 = new Swiper('.swiper-4-half-slides .swiper', {
            loop: true,
            lazy: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                350: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1024: {
                    centeredSlides: true,
                    slidesPerView: 3.5,
                    spaceBetween: 50,
                },
                1300: {
                    centeredSlides: false,
                    slidesPerView: 4,
                    spaceBetween: 100,
                },
            },
        });
    }
}
